import React from 'react';

function Sidebar() {
  return (
    <div className="sidebar">
      <h2>Xcode Releases</h2>
      <p>Discover and download the latest Xcode versions.</p>
    </div>
  );
}

export default Sidebar;