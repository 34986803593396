import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import Card from './components/Card';
import Filter from './components/Filter';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';

function App() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    fetch('https://xcodereleases.com/data.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setFilteredData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter(item =>
        item.version.number.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [filter, data]);

  return (
    <div className="app">
      <Sidebar />
      <div className="main">
        <Filter filter={filter} setFilter={setFilter} />
        <div className="cards">
          {filteredData.length > 0 ? (
            filteredData.map(item => (
              <Card key={item.version.build} item={item} />
            ))
          ) : (
            <p>Loading data...</p>
          )}
        </div>
        <ScrollToTop />
      </div>
      <Footer />
    </div>
  );
}

export default App;