import React from 'react';

function Card({ item }) {
  const swiftVersion = item.compilers?.swift?.[0]?.number || 'N/A';
  const sdks = item.sdks ? Object.keys(item.sdks).join(', ') : 'N/A';
  const downloadUrl = item.links?.download?.url;
  const notesUrl = item.links?.notes?.url;

  return (
    <div className="card">
      <h3>{item.version.number}</h3>
      <p><strong>Release:</strong> {item.version.release.release ? 'Yes' : 'No'}</p>
      <p><strong>Build:</strong> {item.version.build}</p>
      <p><strong>Released:</strong> {item.date.year}-{item.date.month}-{item.date.day}</p>
      <p><strong>Requires:</strong> {item.requires}</p>
      <p><strong>Swift:</strong> {swiftVersion}</p>
      <p><strong>SDKs:</strong> {sdks}</p>
      {downloadUrl && <p><a href={downloadUrl} target="_blank" rel="noopener noreferrer">Download</a></p>}
      {notesUrl && <p><a href={notesUrl} target="_blank" rel="noopener noreferrer">Release Notes</a></p>}
    </div>
  );
}

export default Card;