import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 Xcode Releases. All rights reserved.</p>
      <p>
        <a href="https://xcodereleases.com/data.json" target="_blank" rel="noopener noreferrer">
          Data by xcodereleases
        </a>
      </p>
    </footer>
  );
}

export default Footer;